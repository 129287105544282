import React from "react";
import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import differenceInMonths from "date-fns/differenceInMonths";

import { upperFirst, slugify } from "utils/formatting";

import Box from "components/Box";
import Icon from "components/Icon";
import Link from "components/Link";
import LinkedData from "components/LinkedData";
import MenuButton from "components/MenuButton";
import Text from "components/Text";

import Card from "./Card";

const EventCard = ({ content }) => {
  const { description, location, start, title, htmlLink, icsLink } =
    content.main;

  const today = new Date();
  const startDate = new Date(start);
  let dateFromNow = `in ${formatDistanceToNow(startDate)}`;
  if (startDate < today) {
    dateFromNow = `${formatDistanceToNow(startDate)} ago`;
  } else if (differenceInMonths(startDate, today) > 1) {
    dateFromNow = `${formatDistanceToNow(startDate)} from now`;
  }

  const icsFile = `${slugify(title, { lower: true })}.ics`;

  return (
    <Card variant="event">
      <LinkedData type="event" {...content} />
      <Card.Eyebrow>
        <Icon icon="calendar" />
        &ensp;
        {upperFirst(dateFromNow)}
      </Card.Eyebrow>
      <Card.Heading>{title}</Card.Heading>
      <Card.Body blocks={description} />
      <Card.Footer>
        <Box>
          <Text as="p" variant="small" itemProp="startDate" content={start}>
            <Icon icon="clock" />
            &ensp;
            {format(startDate, "MMMM do, yyyy")}
          </Text>
          {location && (
            <Text as="p" variant="small">
              <Icon icon="map-pin" />
              &ensp;
              {location}
            </Text>
          )}
        </Box>
        <MenuButton variant="plain" value="Add to your calendar">
          <Link to={htmlLink}>
            <Icon icon="external-link" /> Google Calendar
          </Link>
          <Link to={icsLink} download={icsFile}>
            <Icon icon="download" /> Other (.ics)
          </Link>
        </MenuButton>
      </Card.Footer>
    </Card>
  );
};

export default EventCard;

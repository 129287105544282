import React from "react";
import { format } from "date-fns";

import Card from "./Card";

const PostCard = ({ _createdAt, _updatedAt, content }) => {
  const { title, body } = content.main;
  const publishedAt = format(new Date(_updatedAt || _createdAt), "MMM. d, y");
  return (
    <Card variant="post">
      <Card.Eyebrow>{publishedAt}</Card.Eyebrow>
      <Card.Heading>{title}</Card.Heading>
      <Card.Body blocks={body} />
    </Card>
  );
};

export default PostCard;

import React from "react";

import { useSanityGROQQuery } from "state/sanity";

import { styled } from "style";

import EventCard from "components/Card/Event";
import Heading from "components/Heading";
import PostCard from "components/Card/Post";
import ProductCard from "components/Card/Product";

const Container = styled("div")({
  width: "100%",
  maxWidth: "xl",
  mx: "auto",
  mb: 6,
  px: 3,
  "&[data-type='post']": {
    color: "turquoise.500",
  },
  "&[data-type='event']": {
    color: "fuchsia.500",
  },
});

const List = styled("div")({
  display: "flex",
  flexFlow: "column nowrap",
  maxWidth: "md",
  m: 0,
  p: 0,
  listStyle: "none",
});

const Message = styled("div")({
  maxWidth: "md",
  p: 4,
  borderRadius: "md",
  "[data-type='post'] &": {
    color: "turquoise.900",
    backgroundColor: "turquoise.50",
  },
  "[data-type='event'] &": {
    color: "fuchsia.900",
    backgroundColor: "fuchsia.50",
  },
  h3: {
    mb: 0,
  },
});

// TODO
// better error and empty state handling
const DocumentList = ({ title, items: { query, items, type }, sx }) => {
  const { data, error } = useSanityGROQQuery(query);
  const documents = data || items || [];
  let children;

  if (error) {
    // couldn't load documents
    children = (
      <Message>
        <Heading as="h3">Couldn't load items</Heading>
      </Message>
    );
  } else if (query && !data) {
    // loading
    children = (
      <Message>
        <Heading as="h3">Loading…</Heading>
      </Message>
    );
  } else if (documents) {
    const items = documents.map(renderItem).filter(Boolean);
    if (items.length < 1) {
      // no documents to show
      children = (
        <Message>
          <Heading as="h3">{`No ${
            type === "event" ? "events" : "items"
          } to show right now.`}</Heading>
          <p>Please check again soon.</p>
        </Message>
      );
    } else {
      children = <List>{items}</List>;
    }
  }

  function renderItem({ _id, _type, ...item }) {
    if (item.content.main.cancelled) {
      return null;
    }

    switch (_type) {
      case "event": {
        return <EventCard key={_id} {...item} />;
      }
      case "post": {
        return <PostCard key={_id} {...item} />;
      }
      case "product": {
        return <ProductCard key={_id} {...item} />;
      }
      default: {
        return (
          <Heading key={_id}>{`No card component for "${_type}" type`}</Heading>
        );
      }
    }
  }

  return (
    <Container sx={sx || {}} data-type={type}>
      {title && (
        <Heading as="h2" variant="sectionHeading.minor">
          {title}
        </Heading>
      )}
      {children}
    </Container>
  );
};

export default DocumentList;

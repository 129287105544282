import React from "react";
import {
  Menu,
  MenuButton as ReachMenuButton,
  MenuLink,
  MenuItem,
  MenuItems,
  MenuPopover,
} from "@reach/menu-button";
import { positionDefault } from "@reach/popover";

import { getThemeProps, styled } from "style";

import Box from "components/Box";
import Button from "components/Button";
import Icon from "components/Icon";

const defaultProps = {
  variant: "default",
};

const style = {
  button: {
    variant: "buttons.secondary",
    ":active": {
      transform: "none",
    },
  },
  popover: {
    position: "absolute",
    zIndex: "popover",
    display: "block",
    "&[hidden]": {
      display: "none",
    },
  },
  list: {
    m: 0,
    mt: 1,
    py: 2,
    px: 0,
    bg: "white",
    border: "1px solid",
    borderColor: "gray.200",
    borderRadius: "sm",
    boxShadow: "md",
    fontSize: 1,
    listStyle: "none",
    outline: "none",
    overflow: "hidden",
  },
  item: {
    display: "block",
    userSelect: "none",
  },
  link: {
    display: "block",
    px: 3,
    py: 2,
    font: "inherit",
    fontSize: 1,
    textDecoration: "initial",
    color: "inherit",
    cursor: "pointer",
    "&[data-selected]": {
      bg: "gray.100",
    },
  },
};

const Popover = styled(MenuPopover, {
  forwardProps: ["position"],
})();

const MenuButton = ({ children, value, variant, ...props }) => {
  const themeProps = getThemeProps({
    style,
    variant,
    themeKey: "menuButton",
  });

  function renderMenuItem(child) {
    const isLink = !!(child.props.to || child.props.href);
    const isMenuItem = typeof child.props.onSelect === "function";
    if (isLink || isMenuItem) {
      return React.createElement(isLink ? MenuLink : MenuItem, {
        as: child.type,
        isLink,
        ...themeProps("link"),
        ...child.props,
      });
    }

    return child;
  }

  return (
    <Menu>
      <Button as={ReachMenuButton} {...themeProps("button", props)}>
        {value}
        &nbsp;
        <Icon icon="chevron-down" />
      </Button>
      <Popover position={position} {...themeProps("popover")}>
        <Box as={MenuItems} {...themeProps("list")}>
          {React.Children.map(children, child => renderMenuItem(child))}
        </Box>
      </Popover>
    </Menu>
  );
};

MenuButton.defaultProps = defaultProps;

export default MenuButton;

function position(targetRect, popoverRect) {
  const style = positionDefault(targetRect, popoverRect);
  style.minWidth = targetRect.width;
  return style;
}
